<template>
  <nav>
    <div class="nav_logo">
      <Logo name="logo-color" height="36"/>
      <Logo name="logo-name" height="16"/>
    </div>

    <div class="nav_function">
      <div class="nav_text">

      </div>

      <div class="nav_contact">
        <h2>{{ lang.contact }}</h2>
      </div>

      <div class="nav_lang">EN</div>

      <div class="nav_hamburger">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </nav>
</template>

<script>
import { defineComponent } from 'vue'
import Logo from './Logo.vue'

export default defineComponent({
  components: { Logo },
  props: {
    lang: {
      type: Object,
    },
  },
})
</script>

<style scope>
nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  border: black solid;
  border-width: 2px 0 1px 0;
  backdrop-filter: blur(4px);
  font-family: var(--font-brand);
  display: flex;
  flex-direction: column;
  z-index: 20;
  opacity: 0;
  animation: navAppear 1s ease forwards;
}
@keyframes navAppear {
  to {
    opacity: 1;
  }
}


.nav_logo {
  width: 100%;
  height: 54px;
  padding: 0 var(--spacing-medium) 2px var(--spacing-default);
  border: black solid;
  border-width: 0 0 1px 0;
  background-color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-nano);
}

.nav_logo .logo-name {
  translate: 0 2px;
}




.nav_function {
  width: 100%;
  background-color: rgba(245, 245, 245, 0.6);
  display: flex;
}


.nav_text {
  display: flex;
  flex-grow: 1;
}

.nav_contact {
  padding: var(--spacing-tiny);
  border: black solid;
  border-width: 0 0 0 1px;
  background-color: orange;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav_contact h2 {
  height: 100%;
  padding: 0 var(--spacing-default);
  border: black solid 1px;
  border-radius: var(--spacing-nano);
  background-color: gold;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}
.nav_contact:active h2 {
  scale: 0.9;
}

.nav_lang {
  width: 54px;
  min-width: 54px;
  height: 54px;
  border: black solid;
  border-width: 0 0 0 1px;
  cursor: pointer;
  display: none;
  justify-content: center;
  align-items: center;
}

.nav_hamburger {
  width: 54px;
  min-width: 54px;
  height: 54px;
  border: black solid;
  border-width: 0 0 0 1px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-tiny);
}

.nav_hamburger span {
  width: 24px;
  min-width: 24px;
  height: 2px;
  background-color: black;
}




@media screen and (min-width: 500px) {
  nav {
    flex-direction: row;
  }

  .nav_logo {
    width: 250px;
    border-width: 0 1px 0 0;
  }

  .nav_contact:hover {
    background-color: orangered;
  }
  .nav_contact:hover h2 {
    background-color: orange;
  }

  .nav_lang {
    display: flex;
  }
}
</style>