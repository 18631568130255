<template>
  <SlashPage v-if="showSlashPage"/>
  <NavBar v-if="showNav" :lang="lang" @switch-lang="$emit('switchLang')"/>
  <main v-if="!showSlashPage">
    <section>
      <h1>Introduction</h1>

      <h2>Problem</h2>
      <p>Theye don’t know how to change or to respond</p>
      <p>A lack of knowledge (about finance, strategy or the field)</p>
      <p>Theye need an outsider (objective view)</p>
      <p>Theye need someone for a temporary position, job or project.</p>
    </section>
    <section>
      <h1>Networking & Community</h1>
      <p>Partners & Associates: KVK</p>
    </section>
    <section>
      <h1>Discover our pprograms</h1>

      <h2>In-company programs</h2>
      <p>Customized financial training solutions that address your organization's distinct needs.</p>

      <h3>Tailormade</h3>
      <p>
        We provide customized financial training solutions and in-company programs that address each organization's distinct needs.
        We will work in close partnership with your organization throughout the development process to ensure we  deliver a challenging program that accurately reflects and addresses your individual training needs - whether implementing a new company-wide strategy or developing a team.
      </p>

      <h3>Practical application</h3>
      <p>Key learning objectives are delivered using a combination of lectures, practical training sessions and group study. Practical application can be further promoted by incorporating company-specific data and casework.</p>

      <h3>Flexible and convenient</h3>
      <p>Training can be held at our training facilities in Amsterdam or any time and location of your choosing. One designated program coordinator monitors every detail of your program from the delivery of pre-study materials, books and software to managing logistics, and post-program assessment.</p>

      <h2>Open enrollment programs</h2>
      <p>Financial training for professionals - from more specialized advanced topics to the basics of finance.</p>

      <p>
        We specialize in providing quality education for professionals with an academic background and work experience. We offer carefully crafted learning paths designed to help you delve deeper into various facets of finance.
        Whether you seek to sharpen your existing skills or explore new horizons, our short, intense programs are tailored to meet your needs.
        Our programs are designed to provide you with top-notch knowledge and practical skills. Our expert faculty ensure that you will gain a competitive edge in your career. Take the next step in your professional learning path by choosing one or more of our specialized programs.
      </p>
    </section>
    <section>
      <h1>Contact</h1>
      <p>Contact form</p>
    </section>
  </main>
  <footer></footer>
</template>

<script>
import { defineComponent } from 'vue'
import NavBar from '@/components/NavBar.vue'
import SlashPage from '@/components/SlashPage.vue'

export default defineComponent({
  components: { NavBar, SlashPage },
  emits: ['switchLang'],
  props: {
    lang: {
      type: Object,
    },
  },
  data() {
    return {
      showSlashPage: true,
      showNav: false,
    }
  },
  mounted() {
    setTimeout(() => {
      document.querySelector('.slash_page').style.opacity = 0
      this.showNav = true
      setTimeout(() => {
        this.showSlashPage = false
      }, '1000')
    }, '5100')
  }
})
</script>

<style scope>

</style>