<template>
  <div class="slash_page">
    <Logo name="logo-color" height="36"/>
    <Logo name="logo-name" height="16"/>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import Logo from './Logo.vue'

export default defineComponent({
  components: { Logo },
})
</script>

<style scope>
.slash_page {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(82deg, black 50%, #F8F6F0 50%);
  background-size: 250% 100%;
  background-position: 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: splashpPage 2s ease 2s forwards;
  transition: opacity 1s ease;
}
@keyframes splashpPage {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 0;
  }
}

.slash_page .logo-color {
  position: absolute;
  top: calc(50% - 60px);
  left: 50%;
  translate: -50% -50%;
  scale: 5;
  opacity: 0;
  animation: splashLogoMid 5s linear forwards;
}
@keyframes splashLogoLeft {
  30% {
    top: calc(50% - 60px);
    left: 50%;
    translate: -50% -50%;
    scale: 5;
    opacity: 1;
  }
  70% {
    top: calc(50% - 60px);
    left: 50%;
    translate: -50% -50%;
    scale: 5;
    opacity: 1;
  }
  100% {
    top: 8px;
    left: 15.6px;
    translate: 0 0;
    scale: 1;
    opacity: 1;
  }
}
@keyframes splashLogoMid {
  30% {
    top: calc(50% - 60px);
    left: 50%;
    translate: -50% -50%;
    scale: 5;
    opacity: 1;
  }
  70% {
    top: calc(50% - 60px);
    left: 50%;
    translate: -50% -50%;
    scale: 5;
    opacity: 1;
  }
  100% {
    top: 8px;
    left: 50%;
    translate: -208% 0;
    scale: 1;
    opacity: 1;
  }
}

.slash_page .logo-name {
  position: absolute;
  top: calc(50% + 60px);
  left: 50%;
  translate: -50% -50%;
  scale: 2;
  animation: splashLogoNameMid 5s linear forwards;
}
@keyframes splashLogoNameLeft {
  70% {
    top: calc(50% + 60px);
    left: 50%;
    translate: -50% -50%;
    scale: 2;
  }
  100% {
    top: 21px;
    left: 70px;
    translate: 0 0;
    scale: 1;
  }
}
@keyframes splashLogoNameMid {
  70% {
    top: calc(50% + 60px);
    left: 50%;
    translate: -50% -50%;
    scale: 2;
  }
  100% {
    top: 21px;
    left: 50%;
    translate: -33% 0;
    scale: 1;
  }
}

@media screen and (min-width: 500px) {
  .slash_page .logo-color {
    animation: splashLogoLeft 5s linear forwards;
  }

  .slash_page .logo-name {
    animation: splashLogoNameLeft 5s linear forwards;
  }
}
</style>